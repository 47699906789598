<template>
  <v-app id="inspire" class="background">
    <v-app-bar app color="grey lighten-5" fixed>
      <v-avatar size="32">
        <v-img :src="require('@/assets/logo4kant.png')" class="my-3" contain height="32"/>
      </v-avatar>

      <v-tabs centered class="ml-n9" color="primary">
        <v-tab tabindex="1" to="/" class="headertextcolor">LueChai</v-tab>
        <v-tab tabindex="2" to="/info" class="headertextcolor">{{ getHeaderTekst }}</v-tab>
      </v-tabs>
    </v-app-bar>
    <v-main>
    <router-view></router-view>
    </v-main>
    <v-dialog
        v-model="dialog"
        width="auto"
    >
      <v-card>
        <v-card-text class="pa-5">
          Tot en met 12 februari 2025<br />
          is de praktijk gesloten.
        </v-card-text>
        <v-card-actions class="pa-5">
          <v-btn color="success" block @click="dialog = false">Sluiten</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-footer color="grey darken-2" fixed >
      <v-row justify="center" no-gutters>
        <div class="footercolor text-center">
          U kunt een afspraak maken door een Whatsappbericht te sturen of een e-mail.
        </div>
        <div class="footercolor text-center">
          Ook kunt u bellen of voicemail inspreken: 06&nbsp;1558&nbsp;7218
        </div>
      </v-row>
    </v-footer>

  </v-app>
</template>

<script>
export default {
  data: function() {
    return {
      dialog: true
    };
  },
  computed: {
    getHeaderTekst() {
      if (this.$vuetify.breakpoint.name === 'xs'){
        return "Behandelingen"
      }
      return "Behandelingen & tarieven";
    },
  },
}
</script>
<style scoped>
.background {
  background-color: white;
}
.headertextcolor {
  color: goldenrod;
}
.footercolor {
  color: #d1b680;
}
</style>
