<template>
    <div>
      <v-container class="pt-sm-16">
        <v-img alt="" contain max-height="350" :src="require('@/assets/luechai_logo_tekst.png')"></v-img>
      </v-container>
      <v-container aria-hidden="true" fluid style="background-color: #8a8d94">
        <v-container>
          <div class="d-flex flex-row flex-wrap justify-center">
            <div class="blokwidth pr-2">
              <v-img alt="" contain class="white-text imageborder" max-height="400" :src="require('@/assets/behandel1.jpeg')"></v-img>
            </div>
            <div class="blokwidth">
              <a tabindex="3" style="text-decoration: none" href="tel:0615587218">
                <contactblok titel="Telefoon" icon="phone">06 1558 7218</contactblok>
              </a>
              <contactblok titel="Adres" icon="map-marker">
                Vuurlaan 2<br/>
                2408 NB<br/>
                Alphen aan den Rijn
              </contactblok>
              <a tabindex="4" style="text-decoration: none" href="mailto:luechaimassage@gmail.com">
                <contactblok titel="E-mail" icon="email">luechaimassage&#8203;@gmail.com</contactblok>
              </a>
              <contactblok titel="Openingstijden" icon="calendar-check">
                <table>
                  <tr>
                    <th scope="col" colspan="2">Alleen geopend op afspraak</th>
                  </tr>
                  <tr>
                    <th scope="row" class="tabeldata">Maandag</th>
                    <td>10:00&nbsp;-&nbsp;20:00</td>
                  </tr>
                  <tr>
                    <th scope="row" class="tabeldata">Dinsdag</th>
                    <td>10:00&nbsp;-&nbsp;20:00</td>
                  </tr>
                  <tr>
                    <th scope="row" class="tabeldata">Woensdag</th>
                    <td>10:00&nbsp;-&nbsp;20:00</td>
                  </tr>
                  <tr>
                    <th scope="row" class="tabeldata">Vrijdag</th>
                    <td>10:00&nbsp;-&nbsp;20:00</td>
                  </tr>
                  <tr>
                    <th scope="row" class="tabeldata">Zaterdag</th>
                    <td>10:00&nbsp;-&nbsp;20:00</td>
                  </tr>
                  <tr>
                    <td class="pt-5" rowspan="2">Let op; gesloten tot en met 12 februari 2025</td>
                  </tr>
                </table>
              </contactblok>
            </div>
          </div>
        </v-container>
        <v-container aria-hidden="true" fluid style="background-color: #8a8d94">
          <div class="pageheight pt-sm-16">
          </div>
        </v-container>
      </v-container>
    </div>
</template>

<script>
import Contactblok from '@/components/Contactblok';
  export default {
    name: 'Home',
    components: {Contactblok}
  }
</script>
<style scoped>
.tabeldata {
  text-align: left;
  padding-right: 5px;
}
.pageheight {
  height:calc(100vh - 572px)
}
.imageborder {
  border: 8px white solid;
}
.blokwidth{
  width: 40vw;
  min-width: 310px;
  max-width: 510px;
}
</style>
